import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store'
import { onSignInSuccess, onSignOutSuccess } from '../store/auth/sessionSlice'

const BaseService = axios.create({
    timeout: 60000,
    withCredentials: true,
    baseURL: appConfig.apiPrefix,
})

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        let accessToken = persistData?.auth?.session?.token

        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.token
        }

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
            ] = `${TOKEN_TYPE}${accessToken}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { response } = error
        const originalRequest = error.config;
        if (response && response.status === 401 ) {
            originalRequest._retry = true;
            return await axios.post(`${appConfig.apiPrefix+'/auth/refresh'}`,{},{withCredentials: true}).then(async(response)=>{
                console.log(response);
                if (response.status === 200) {
                store.dispatch(onSignInSuccess(response.data))
                originalRequest.headers['Authorization'] = `Bearer ${response.data}`;
                return BaseService(originalRequest);
            }
            if(response.status === 401){
                originalRequest._retry = false;
                store.dispatch(onSignOutSuccess())
                return Promise.reject(response)
            }
            }).catch((error)=>{
                console.log(error)
                originalRequest._retry = false;
                store.dispatch(onSignOutSuccess())
                return Promise.reject(error)
            })
        }
        return Promise.reject(error)
    }
)

export default BaseService
