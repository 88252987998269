const appConfig = {
    apiPrefix: 'https://api.jma.sa',
    imgBaseUrl: '',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/confirm-password',
    locale: 'ar',
    enableMock: false,
}

export default appConfig
