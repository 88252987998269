import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import BaseService from 'services/BaseService'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const res = await BaseService.post('/auth/login',values)
            if (res.status === 200) {
                const { access_token , user } = res.data
                dispatch(onSignInSuccess(access_token))
                dispatch(setUser(user))
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: 'تم تسجيل الدخول بنجاح',
                }
            }
        } catch (errors) {
            console.log(errors)
            return {
                status: 'failed',
                message:
                    errors?.response?.data?.message ||
                    errors.toString() ||
                    'حدث خطأ',
            }
        }
    }


    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await BaseService.post('/auth/logout').then((res)=>{
            if(res.status === 200){
                handleSignOut()
            }
        })
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signOut,
    }
}

export default useAuth
