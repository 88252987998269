import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    id: 2,
    ar_full_name: "",
    en_full_name: "",
    username: "",
    email: "",
    phone: "",
    role: "",
    created_at: "",
    pfp: "",
    email_verified: true,
    is_active: true,
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => action.payload,
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
